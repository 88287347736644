<template>
  <div class="mb-0" v-if="amReady">
    <div class="dg-module-container text-capitalize mb-2" v-if="formHeader">
      <h5>{{ this.getFormTitle }}</h5>
    </div>


    <div class="">
      <div class="sub-title">{{ title }}</div>
      <el-row class="mt-3">
        <el-col :class="$langConfig.cssClass">
          <dg-text-field
              :control="control"
              :disabled="!isAdmin" :field_span="24"
              :label_span="24"
              :translateLabel='false'
              field_mark=""
              inputClass="big-textarea-style"
              inputPrepend=""
              label=""
              name="note"
              placeholder=""
              rows="16"
              rules=""
              type="textarea"></dg-text-field>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="10">
      <el-col :span="24" class="mt-2 border-top">
        <el-col :class="$langConfig.cssClass" :span="12" class="mt-4">
          <label class="error-field-validate-message text-capitalize"
                 v-show="!this.validData">{{
              (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria')
            }}</label>
        </el-col>
        <el-col :span="12" class="pt-3 text-right">
          <dg-confirm-button
              :loading="requestOn"
              :onConfirm="moduleDeleteAction"
              :translate="false"
              confirmButtonType="danger"
              fieldSize="mini"
              icon="el-icon-delete"
              popIcon="el-icon-delete"
              style="margin-right: 10px"
              text="remove"
              title="sure to removing this record ?"
              type="danger"
              v-if="editMode"></dg-confirm-button>

          <dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                     type="warning" v-if="editable && editMode"></dg-button>
          <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                     v-if="!editable && editMode"></dg-button>

          <dg-button :click="createModule" :loading="requestOn" :translate="false" fieldSize="mini"
                     icon="el-icon-check"
                     text="Save" type="success"
                     v-if="editable && isAdmin"></dg-button>

          <!--<dg-confirm-button
              :icon="actionButtonIcon"
              :loading="requestOn"
              :onConfirm="createModule"
              :text="actionButtonLabel"
              :translate="false"
              :type="actionButtonType"
              fieldSize="mini"
              v-if="editable"></dg-confirm-button>-->
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import module from './../index.js'

export default {
  beforeMount() {
    this.getFormData()
  },
  data() {
    return {
      isAdmin: $xBundle['CURRENT_USER_PROFILE'].profile_id === 'ADMIN',
      noteLoaded: false,
      currentNote: {},
      module: {...module},
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      requestErrorMessage: null,
    }
  },
  props: {
    title: {
      default: 'Note',
    },
    noteType: {
      default: 1,
    },
    client_id: {
      required: true,
    }
  },
  methods: {
    getFormData() {
      let promises = []
      promises.push(this.getNote({client_id: this.client_id, type_id: this.noteType}).then( r => {
        if (r != '') {
          this.noteLoaded = true
          this.currentNote = r
          this.dataModel.note = r.content
        }
      }))
      Promise.all(promises).then((values) => {
        this.amReady = true
      })
    },
    ...$mapActions(module.info.fullName + '-api', {
      userDataAuthFields: 'userDataAuthFields',
      getEntityDropDownList: 'getDropDownList',
      getNote: 'get'
    }),
    async createModule() {
      this.validateFields().then(async result => {
        if (!result) {
          this.requestErrorMessage = null;
          return
        }

        this.requestOn = true
        let context = this;
        let apiMethod = 'createOrUpdate';
        let apiParams = {
          data: {
            'client_id': this.client_id,
            'content': this.dataModel.note,
            'type_id': this.noteType
          }
        };
        if (this.editMode) apiParams.id = this.dataToBind.id;

        $store.dispatch(this.module.info.fullName + '-api/' + apiMethod, apiParams).then(async response => {
          let entity = response.data
          $services['notify']['success']("Saved")

          $vue.nextTick(() => {
            if (context.onEditCallback) context.onEditCallback(response.data)
          })
        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {
          this.requestOn = false
        })
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
